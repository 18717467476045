.carousel-caption {
  position: static;
  display: block;
  margin: 0 auto;
  max-width: 380px;
  text-align: left;
  padding-top: 1.75rem;
}

.carousel-indicators {
  bottom: -1.5rem;

  svg {
    margin: .25em;

    circle {
      fill: transparent;
      stroke: $hitcoachNegative;
      stroke-width: 1;
      stroke-linecap: butt;
      stroke-dasharray: 0;
    }

    &.active circle {
      fill: white;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNi4xMjEiIGhlaWdodD0iNDkuNDE0IiB2aWV3Qm94PSIwIDAgMjYuMTIxIDQ5LjQxNCI+CiAgPHBhdGggaWQ9IlBmYWRfNTEiIGRhdGEtbmFtZT0iUGZhZCA1MSIgZD0iTTkzOSwzODMybC0yNCwyNCwyNCwyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkxMy41ODYgLTM4MzEuMjkzKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNi4xMjEiIGhlaWdodD0iNDkuNDE0IiB2aWV3Qm94PSIwIDAgMjYuMTIxIDQ5LjQxNCI+CiAgPHBhdGggaWQ9IlBmYWRfNTIiIGRhdGEtbmFtZT0iUGZhZCA1MiIgZD0iTTkzOSwzODMybC0yNCwyNCwyNCwyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTM5LjcwNyAzODgwLjcwNykgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=");
}

.carousel-control-prev {
  left: 0.75rem;
}

.carousel-control-next {
  right: 0.75rem;
}

.carousel-control-prev, .carousel-control-next {
  top: 100px;
  align-items: flex-start;

  @include media-breakpoint-up(md) {
    top: 0;
    align-items: center;
  }
}

.carousel-control-next-icon, .carousel-control-prev-icon {

  height: 1.5rem;

  @include media-breakpoint-up(lg) {
    height: 48px;
  }
}
