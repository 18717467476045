body.s404 {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .h_max {
    @include media-breakpoint-down(lg) {
      font-size: 4rem;
    }
  }

  .h-100.wrapped {
    display: flex;
    align-items: start;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      justify-content: center;
      align-items: flex-start;
    }

    a.cta {
      align-self: center;
      @include media-breakpoint-up(lg) {
        align-self: flex-start;
      }
    }
  }
}

