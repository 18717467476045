section.kontakt {
  .h-100 {
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and(min-width: 1000px) {
      padding-left: calc((100vw - 1000px) / 2);
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      align-content: center;
    }
  }

  .bg-primary > .h1 {
    font-size: 44px;
  }

  div[class^="col-"] {
    @include media-breakpoint-up(lg) {
      height: 620px;
    }
  }

  h4 {
    font-size: 1rem;
  }

  .angle-left {

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: center;
    }

    &:before {
      border-bottom-color: white;
      mix-blend-mode: normal;
    }
  }

  a.cta {
    z-index: 2;
    white-space: nowrap;
    position: relative;
    @include media-breakpoint-up(lg) {
      position: absolute;
      right: -190px;
      bottom: 2rem;
    }
  }

  address a {
    color: $body-color;

    &:hover {
      color: $body-color;
    }
  }

  #facebook {
    font-size: 0;
  }

}
