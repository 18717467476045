$angle-bottom-width: 4320px;
$angle-left-width: 744px;
.indented {
  padding: 0 12px;

  @include media-breakpoint-up(lg) {
    //(1250 - 1000 / 2)
    padding-left: 123.5px;
    padding-right: 12px;
  }
}

.angle-left {
  padding-right: .75rem;
  @media screen and (min-width: 1000px) {
    padding-right: calc((100vw - 1012px) / 2);
  }
  @include media-breakpoint-up(lg) {
    padding-left: 0;
    &:before {
      content: "";
      mix-blend-mode: multiply;
      border-bottom: $angle-bottom-width solid $hitcoachBlue;
      border-left: $angle-left-width solid transparent;
      position: absolute;
      right: 99.99%;
      top: 0;
    }
  }
}

.angle-right {
  @include media-breakpoint-up(lg) {
    padding-right: 0;
    padding-left: calc((100vw - 1012px) / 2);
    &:before {
      content: "";
      border-top: 58vw solid $white;
      border-right: 10vw solid transparent;
      position: absolute;
      left: 99.99%;
      top: 0;
      z-index: 1;
    }
  }
}

.sm-container {
  @media (max-width: map_get($grid-breakpoints, lg)) and (min-width: map_get($grid-breakpoints, md)) {
    max-width: map_get($container-max-widths, md);
  }
  @media (max-width: map_get($grid-breakpoints, md)) and (min-width: map_get($grid-breakpoints, sm)) {
    max-width: map_get($container-max-widths, sm);
  }
}
