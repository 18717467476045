section.eindrücke {
  @include media-breakpoint-down(sm) {
    & > .container {
      text-align: center;
    }

  }

  a.lightbox-link {
    display: inline-block;
    position: relative;
    box-shadow: 0px 0px 30px #00000029;

    &:hover {
      cursor: pointer;
    }

    > span {
      visibility: hidden;
      height: 0;
      display: block;
      background-color: transparent;
      font-style: italic;
      transition: background-color .2s ease-in-out;
    }

    &:hover > span {
      display: flex;
      visibility: visible;
      position: absolute;
      height: 100%;
      width: 100%;
      background: transparentize($primary, 0.2);
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-transform: uppercase;
      color: white;
      font-size: 20px;
      font-family: prohibition, $font-family-sans-serif;

      img {
        width: 1rem;
        margin-bottom: 0.5rem;
      }
    }


  }

  .modal-dialog {
    margin-top: 5%;
  }

  .modal-body {
    figcaption {
      position: absolute;
      bottom: 0;
      left: 50%;
      background: rgba(0, 159, 227, 0.8);
      transform: translateX(-50%);
      width: 100%;
      padding: .5rem 1rem;
      text-align: center;
      color: $white;
    }
    img {
      width: 100%;
    }
  }

  .thumb {
    padding: 1rem 1rem;

  }

  .close {
    background-color: $hitcoachLightGray;
    opacity: 1;
    position: absolute;
    right: 1px;
    padding: 1rem;
    z-index: 1500;

    &:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):focus {
      opacity: 1;
    }
  }
}
