@keyframes jump {
  0% {
    transform: translateY(0px);
  }
  5% {
    transform: translateY(10px);
  }
  10% {
    transform: translateY(0px);
  }
  15% {
    transform: translateY(5px);
  }
  20% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }

}

#scroll-down {
  animation: jump, ease-in-out;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-delay: 3s;
}
