section.agb {
  width: 100%;

  ol {
    list-style-type: upper-roman;

    li {
      margin-bottom: 1rem;
    }
  }

  a, a:hover {
    color: $body-color;

  }
}

body.agb {
  .h-100 {
    display: flex;
    align-items: center;
  }

  .h_max {
    line-height: 1.05;
    hyphens: manual;
    font-size: 3rem;

    @include media-breakpoint-between(md, lg) {
      font-size: 4rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 5.25rem;
    }
  }
}
