@include media-breakpoint-up(lg) {
  .container-fluid {
    padding: 0;
  }
}

ul {
  padding-inline-start: 0;
}

* ::selection {
  background: $primary;
  color: $hitcoachNegative;
}

a {
  &:hover {
    text-decoration: none;
  }
}

body {
  width: 100%;
  height: 100vh;
}

.wrapped {
  @include media-breakpoint-up(lg) {
    max-width: 625px;
  }
}
