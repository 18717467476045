section.coach {
  .angle-right {
    position: relative;
    justify-content: center;

    a {
      z-index: 2;
      white-space: nowrap;
      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 2rem;
      }

      @include media-breakpoint-up(lg) {
        left: 98%;
      }
    }
  }
}
