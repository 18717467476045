.arbeitsweise {
  .angle-left {
    flex-direction: column;
    justify-content: center;

    &:before {
      border-bottom-color: $white;
      mix-blend-mode: normal;
    }
  }

  @include media-breakpoint-up(lg) {
    img {
      width: 100%;
    }
  }
}
