section.investition {
  @include media-breakpoint-up(lg) {
    div[class*="col"] {
      &:nth-child(2) {
        .card {
          margin-right: 1.33rem;
        }
      }

      &:nth-child(3) {
        .card {
          margin: 0 0.66rem;
        }
      }

      &:nth-child(4) {
        .card {
          margin-left: 1.33rem;
        }
      }
    }
    a {
      &:hover, &:focus {
        cursor: initial;
      }
    }
  }

}
