footer {
  p a {
    color: $body-color;

    &:hover {
      color: $body-color;
    }
  }

  a:hover {
    color: $hitcoachBlue;
  }

  @include media-breakpoint-up(lg) {
    .row > div {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

}


