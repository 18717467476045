.home {
  .hero .h-100 {
    @include media-breakpoint-down( md ){
      flex-direction: column;
    }
  }
}

section.hero {
  .h-100 {
    position: relative;

  }

  .h_max {
    color: $hitcoachNegative;

    span {

      @include media-breakpoint-up(md) {
        padding-left: .9rem;
      }
    }
  }

  .scroll-down {
    position: absolute;
    text-align: center;
    top: 85%;

    @include media-breakpoint-down(md) {
      top: -6rem;
      left: 50%;
      transform: translateX(-50%);
    }

    p {
      font-size: 20px;
      font-style: italic;
      font-family: Prohibition, $font-family-sans-serif;
      text-transform: uppercase;
      color: $hitcoachNegative;
    }
  }
}
