section.laufbahn {
  ul {
    list-style-type: none;
    border-left: solid 3px $primary;

    li {
      position: relative;
      &:before {

        content: "";
        position: absolute;
        right: calc(100% + 1.4rem);
        top: 12%;

        background-image: url("../images/icons/kreis-timeline.svg");
        height: 25px;
        width: 25px;
      }
    }
  }
}
