@charset "UTF-8";
.wrap {
  max-width: 1250px;
  margin: 0 auto; }

/*z. B. Abstand zwischen Paragraphen
smallMargin: 1rem; m*-1
*/
/*z. B. Abstand zwischen Headlines und Paragraphen
middleMargin: 2rem; m*-2
*/
/*z. B. Abstand zwischen Section Hero Section der Coach
bigMargin: 4rem; m*-4
*/
/*z. B. Abstand zwischen Section der Coach und Section meine Laufbahn
maxMargin: 8rem; m*-8
*/
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #009FE3;
  --secondary: #D9000D;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1097px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: franklin-gothic-urw, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.42;
  color: #3B3C43;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #009FE3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: inherit;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #86888C;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1097px) {
    .container {
      max-width: 1000px; } }

.container-fluid, .container-sm, .container-md, .container-lg {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 1097px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1000px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1097px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #3B3C43; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 2px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 4px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 4px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 2px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 2px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 4px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #3B3C43;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e4f7; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7acdf0; }

.table-hover .table-primary:hover {
  background-color: #a1dbf4; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a1dbf4; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f4b8bb; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #eb7a81; }

.table-hover .table-secondary:hover {
  background-color: #f1a2a6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f1a2a6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1096.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #3B3C43;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1097px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #009FE3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 2px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -2px; }
  .nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #009FE3; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3225rem;
  padding-bottom: 0.3225rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1096.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1097px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: #fff; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: transparent; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: #fff; }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0 0 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0 0; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card-deck .card {
  margin-bottom: 0; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: 0;
    margin-left: 0; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0; } }

.card-group > .card {
  margin-bottom: 0; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: 0; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.close {
  float: right;
  font-size: 0;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #009FE3; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.42; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.25rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0rem;
  border-top: 0 solid transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 60vw;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1097px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 1;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #009FE3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #007bb0 !important; }

.bg-secondary {
  background-color: #D9000D !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #a6000a !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 2px solid #dee2e6 !important; }

.border-top {
  border-top: 2px solid #dee2e6 !important; }

.border-right {
  border-right: 2px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 2px solid #dee2e6 !important; }

.border-left {
  border-left: 2px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #009FE3 !important; }

.border-secondary {
  border-color: #D9000D !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1097px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1097px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1097px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 1rem !important; }

.mt-1,
.my-1 {
  margin-top: 1rem !important; }

.mr-1,
.mx-1 {
  margin-right: 1rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 1rem !important; }

.ml-1,
.mx-1 {
  margin-left: 1rem !important; }

.m-2 {
  margin: 2rem !important; }

.mt-2,
.my-2 {
  margin-top: 2rem !important; }

.mr-2,
.mx-2 {
  margin-right: 2rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 2rem !important; }

.ml-2,
.mx-2 {
  margin-left: 2rem !important; }

.m-3 {
  margin: 3rem !important; }

.mt-3,
.my-3 {
  margin-top: 3rem !important; }

.mr-3,
.mx-3 {
  margin-right: 3rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 3rem !important; }

.ml-3,
.mx-3 {
  margin-left: 3rem !important; }

.m-4 {
  margin: 4rem !important; }

.mt-4,
.my-4 {
  margin-top: 4rem !important; }

.mr-4,
.mx-4 {
  margin-right: 4rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 4rem !important; }

.ml-4,
.mx-4 {
  margin-left: 4rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-75 {
  margin: 0.75rem !important; }

.mt-75,
.my-75 {
  margin-top: 0.75rem !important; }

.mr-75,
.mx-75 {
  margin-right: 0.75rem !important; }

.mb-75,
.my-75 {
  margin-bottom: 0.75rem !important; }

.ml-75,
.mx-75 {
  margin-left: 0.75rem !important; }

.m-8 {
  margin: 8rem !important; }

.mt-8,
.my-8 {
  margin-top: 8rem !important; }

.mr-8,
.mx-8 {
  margin-right: 8rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 8rem !important; }

.ml-8,
.mx-8 {
  margin-left: 8rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 1rem !important; }

.pt-1,
.py-1 {
  padding-top: 1rem !important; }

.pr-1,
.px-1 {
  padding-right: 1rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 1rem !important; }

.pl-1,
.px-1 {
  padding-left: 1rem !important; }

.p-2 {
  padding: 2rem !important; }

.pt-2,
.py-2 {
  padding-top: 2rem !important; }

.pr-2,
.px-2 {
  padding-right: 2rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 2rem !important; }

.pl-2,
.px-2 {
  padding-left: 2rem !important; }

.p-3 {
  padding: 3rem !important; }

.pt-3,
.py-3 {
  padding-top: 3rem !important; }

.pr-3,
.px-3 {
  padding-right: 3rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 3rem !important; }

.pl-3,
.px-3 {
  padding-left: 3rem !important; }

.p-4 {
  padding: 4rem !important; }

.pt-4,
.py-4 {
  padding-top: 4rem !important; }

.pr-4,
.px-4 {
  padding-right: 4rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 4rem !important; }

.pl-4,
.px-4 {
  padding-left: 4rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-75 {
  padding: 0.75rem !important; }

.pt-75,
.py-75 {
  padding-top: 0.75rem !important; }

.pr-75,
.px-75 {
  padding-right: 0.75rem !important; }

.pb-75,
.py-75 {
  padding-bottom: 0.75rem !important; }

.pl-75,
.px-75 {
  padding-left: 0.75rem !important; }

.p-8 {
  padding: 8rem !important; }

.pt-8,
.py-8 {
  padding-top: 8rem !important; }

.pr-8,
.px-8 {
  padding-right: 8rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 8rem !important; }

.pl-8,
.px-8 {
  padding-left: 8rem !important; }

.m-n1 {
  margin: -1rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -1rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -1rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -1rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -1rem !important; }

.m-n2 {
  margin: -2rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -2rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -2rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -2rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -2rem !important; }

.m-n3 {
  margin: -3rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -3rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -3rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -3rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -3rem !important; }

.m-n4 {
  margin: -4rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -4rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -4rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -4rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -4rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n75 {
  margin: -0.75rem !important; }

.mt-n75,
.my-n75 {
  margin-top: -0.75rem !important; }

.mr-n75,
.mx-n75 {
  margin-right: -0.75rem !important; }

.mb-n75,
.my-n75 {
  margin-bottom: -0.75rem !important; }

.ml-n75,
.mx-n75 {
  margin-left: -0.75rem !important; }

.m-n8 {
  margin: -8rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -8rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -8rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -8rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -8rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 1rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important; }
  .m-sm-2 {
    margin: 2rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 2rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 2rem !important; }
  .m-sm-3 {
    margin: 3rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 3rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 3rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 3rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 3rem !important; }
  .m-sm-4 {
    margin: 4rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 4rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 4rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 4rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 4rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-75 {
    margin: 0.75rem !important; }
  .mt-sm-75,
  .my-sm-75 {
    margin-top: 0.75rem !important; }
  .mr-sm-75,
  .mx-sm-75 {
    margin-right: 0.75rem !important; }
  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-75,
  .mx-sm-75 {
    margin-left: 0.75rem !important; }
  .m-sm-8 {
    margin: 8rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 1rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important; }
  .p-sm-2 {
    padding: 2rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 2rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 2rem !important; }
  .p-sm-3 {
    padding: 3rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 3rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 3rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 3rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 3rem !important; }
  .p-sm-4 {
    padding: 4rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 4rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 4rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 4rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 4rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-75 {
    padding: 0.75rem !important; }
  .pt-sm-75,
  .py-sm-75 {
    padding-top: 0.75rem !important; }
  .pr-sm-75,
  .px-sm-75 {
    padding-right: 0.75rem !important; }
  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-75,
  .px-sm-75 {
    padding-left: 0.75rem !important; }
  .p-sm-8 {
    padding: 8rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important; }
  .m-sm-n1 {
    margin: -1rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1rem !important; }
  .m-sm-n2 {
    margin: -2rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -2rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -2rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -2rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -2rem !important; }
  .m-sm-n3 {
    margin: -3rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -3rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -3rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -3rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -3rem !important; }
  .m-sm-n4 {
    margin: -4rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -4rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -4rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -4rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -4rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n75 {
    margin: -0.75rem !important; }
  .mt-sm-n75,
  .my-sm-n75 {
    margin-top: -0.75rem !important; }
  .mr-sm-n75,
  .mx-sm-n75 {
    margin-right: -0.75rem !important; }
  .mb-sm-n75,
  .my-sm-n75 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n75,
  .mx-sm-n75 {
    margin-left: -0.75rem !important; }
  .m-sm-n8 {
    margin: -8rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 1rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1rem !important; }
  .m-md-2 {
    margin: 2rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 2rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 2rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 2rem !important; }
  .m-md-3 {
    margin: 3rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 3rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 3rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 3rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 3rem !important; }
  .m-md-4 {
    margin: 4rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 4rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 4rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 4rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 4rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-75 {
    margin: 0.75rem !important; }
  .mt-md-75,
  .my-md-75 {
    margin-top: 0.75rem !important; }
  .mr-md-75,
  .mx-md-75 {
    margin-right: 0.75rem !important; }
  .mb-md-75,
  .my-md-75 {
    margin-bottom: 0.75rem !important; }
  .ml-md-75,
  .mx-md-75 {
    margin-left: 0.75rem !important; }
  .m-md-8 {
    margin: 8rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 1rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1rem !important; }
  .p-md-2 {
    padding: 2rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 2rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 2rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 2rem !important; }
  .p-md-3 {
    padding: 3rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 3rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 3rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 3rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 3rem !important; }
  .p-md-4 {
    padding: 4rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 4rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 4rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 4rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 4rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-75 {
    padding: 0.75rem !important; }
  .pt-md-75,
  .py-md-75 {
    padding-top: 0.75rem !important; }
  .pr-md-75,
  .px-md-75 {
    padding-right: 0.75rem !important; }
  .pb-md-75,
  .py-md-75 {
    padding-bottom: 0.75rem !important; }
  .pl-md-75,
  .px-md-75 {
    padding-left: 0.75rem !important; }
  .p-md-8 {
    padding: 8rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important; }
  .m-md-n1 {
    margin: -1rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1rem !important; }
  .m-md-n2 {
    margin: -2rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -2rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -2rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -2rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -2rem !important; }
  .m-md-n3 {
    margin: -3rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -3rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -3rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -3rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -3rem !important; }
  .m-md-n4 {
    margin: -4rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -4rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -4rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -4rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -4rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n75 {
    margin: -0.75rem !important; }
  .mt-md-n75,
  .my-md-n75 {
    margin-top: -0.75rem !important; }
  .mr-md-n75,
  .mx-md-n75 {
    margin-right: -0.75rem !important; }
  .mb-md-n75,
  .my-md-n75 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n75,
  .mx-md-n75 {
    margin-left: -0.75rem !important; }
  .m-md-n8 {
    margin: -8rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1097px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 1rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important; }
  .m-lg-2 {
    margin: 2rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 2rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 2rem !important; }
  .m-lg-3 {
    margin: 3rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 3rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 3rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 3rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 3rem !important; }
  .m-lg-4 {
    margin: 4rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 4rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 4rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 4rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 4rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-75 {
    margin: 0.75rem !important; }
  .mt-lg-75,
  .my-lg-75 {
    margin-top: 0.75rem !important; }
  .mr-lg-75,
  .mx-lg-75 {
    margin-right: 0.75rem !important; }
  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-75,
  .mx-lg-75 {
    margin-left: 0.75rem !important; }
  .m-lg-8 {
    margin: 8rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 1rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important; }
  .p-lg-2 {
    padding: 2rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 2rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 2rem !important; }
  .p-lg-3 {
    padding: 3rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 3rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 3rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 3rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 3rem !important; }
  .p-lg-4 {
    padding: 4rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 4rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 4rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 4rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 4rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-75 {
    padding: 0.75rem !important; }
  .pt-lg-75,
  .py-lg-75 {
    padding-top: 0.75rem !important; }
  .pr-lg-75,
  .px-lg-75 {
    padding-right: 0.75rem !important; }
  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-75,
  .px-lg-75 {
    padding-left: 0.75rem !important; }
  .p-lg-8 {
    padding: 8rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important; }
  .m-lg-n1 {
    margin: -1rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1rem !important; }
  .m-lg-n2 {
    margin: -2rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -2rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -2rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -2rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -2rem !important; }
  .m-lg-n3 {
    margin: -3rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -3rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -3rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -3rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -3rem !important; }
  .m-lg-n4 {
    margin: -4rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -4rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -4rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -4rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -4rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n75 {
    margin: -0.75rem !important; }
  .mt-lg-n75,
  .my-lg-n75 {
    margin-top: -0.75rem !important; }
  .mr-lg-n75,
  .mx-lg-n75 {
    margin-right: -0.75rem !important; }
  .mb-lg-n75,
  .my-lg-n75 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n75,
  .mx-lg-n75 {
    margin-left: -0.75rem !important; }
  .m-lg-n8 {
    margin: -8rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 1rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1rem !important; }
  .m-xl-2 {
    margin: 2rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 2rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 2rem !important; }
  .m-xl-3 {
    margin: 3rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 3rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 3rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 3rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 3rem !important; }
  .m-xl-4 {
    margin: 4rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 4rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 4rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 4rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 4rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-75 {
    margin: 0.75rem !important; }
  .mt-xl-75,
  .my-xl-75 {
    margin-top: 0.75rem !important; }
  .mr-xl-75,
  .mx-xl-75 {
    margin-right: 0.75rem !important; }
  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-75,
  .mx-xl-75 {
    margin-left: 0.75rem !important; }
  .m-xl-8 {
    margin: 8rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 1rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1rem !important; }
  .p-xl-2 {
    padding: 2rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 2rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 2rem !important; }
  .p-xl-3 {
    padding: 3rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 3rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 3rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 3rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 3rem !important; }
  .p-xl-4 {
    padding: 4rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 4rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 4rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 4rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 4rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-75 {
    padding: 0.75rem !important; }
  .pt-xl-75,
  .py-xl-75 {
    padding-top: 0.75rem !important; }
  .pr-xl-75,
  .px-xl-75 {
    padding-right: 0.75rem !important; }
  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-75,
  .px-xl-75 {
    padding-left: 0.75rem !important; }
  .p-xl-8 {
    padding: 8rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important; }
  .m-xl-n1 {
    margin: -1rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1rem !important; }
  .m-xl-n2 {
    margin: -2rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -2rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -2rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -2rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -2rem !important; }
  .m-xl-n3 {
    margin: -3rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -3rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -3rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -3rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -3rem !important; }
  .m-xl-n4 {
    margin: -4rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -4rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -4rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -4rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -4rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n75 {
    margin: -0.75rem !important; }
  .mt-xl-n75,
  .my-xl-n75 {
    margin-top: -0.75rem !important; }
  .mr-xl-n75,
  .mx-xl-n75 {
    margin-right: -0.75rem !important; }
  .mb-xl-n75,
  .my-xl-n75 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n75,
  .mx-xl-n75 {
    margin-left: -0.75rem !important; }
  .m-xl-n8 {
    margin: -8rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -8rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -8rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -8rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -8rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1097px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #009FE3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #006997 !important; }

.text-secondary {
  color: #D9000D !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #8d0008 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #3B3C43 !important; }

.text-muted {
  color: #86888C !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

body.lb-disable-scrolling {
  overflow: hidden; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em; }

.lb-data .lb-caption a {
  color: #4ae; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.indented {
  padding: 0 12px; }
  @media (min-width: 1097px) {
    .indented {
      padding-left: 123.5px;
      padding-right: 12px; } }

.angle-left {
  padding-right: .75rem; }
  @media screen and (min-width: 1000px) {
    .angle-left {
      padding-right: calc((100vw - 1012px) / 2); } }
  @media (min-width: 1097px) {
    .angle-left {
      padding-left: 0; }
      .angle-left:before {
        content: "";
        mix-blend-mode: multiply;
        border-bottom: 4320px solid #009FE3;
        border-left: 744px solid transparent;
        position: absolute;
        right: 99.99%;
        top: 0; } }

@media (min-width: 1097px) {
  .angle-right {
    padding-right: 0;
    padding-left: calc((100vw - 1012px) / 2); }
    .angle-right:before {
      content: "";
      border-top: 58vw solid #fff;
      border-right: 10vw solid transparent;
      position: absolute;
      left: 99.99%;
      top: 0;
      z-index: 1; } }

@media (max-width: 1097px) and (min-width: 768px) {
  .sm-container {
    max-width: 720px; } }

@media (max-width: 768px) and (min-width: 576px) {
  .sm-container {
    max-width: 540px; } }

.negative {
  color: #fff; }

html {
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  hyphens: auto; }

body {
  margin: 0;
  font-style: normal;
  text-align: left; }

.small {
  font-size: 0.66rem; }

.bold {
  font-weight: 500; }

.italic {
  font-style: italic; }

.strong {
  color: #009FE3;
  font-weight: 500;
  font-style: italic; }

.h_max, .h1, .h3, .btn {
  font-family: prohibition, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  font-style: italic;
  color: #009FE3;
  letter-spacing: .02em;
  text-transform: uppercase; }

.btn {
  font-size: 1rem;
  font-family: prohibition, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  @media (min-width: 576px) {
    .btn {
      font-size: 1.38rem; } }
  .btn.btn-primary {
    background: #009FE3; }

h1, h2, h3, h4, h5, h6 {
  line-height: 100%; }

/*Die Headline im Hero*/
.h_max {
  font-size: 4rem;
  line-height: 90%; }
  @media (min-width: 576px) {
    .h_max {
      font-size: 6.55rem; } }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.38rem;
  font-weight: 500;
  font-style: italic;
  color: #86888C; }

h3, .h3 {
  font-size: 1.77rem; }

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
  color: #009FE3; }

h4 {
  font-size: 1.22rem; }

h5, h6 {
  font-size: 1rem; }

.h1 + span.h2 {
  margin-top: -1.5rem;
  margin-bottom: 1rem;
  display: block; }

a.btn-primary {
  position: relative;
  margin-bottom: 2px; }
  a.btn-primary[aria-expanded="true"] {
    margin-bottom: 0; }
  a.btn-primary[aria-expanded="false"]:after {
    background-image: url("/images/icons/+.svg"); }
  a.btn-primary:after {
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    background-image: url("/images/icons/-.svg");
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-100%, -50%); }

img {
  width: 100%;
  height: auto; }

h4.card-subtitle {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 1.5rem; }

.card-body {
  position: relative;
  padding-bottom: 5rem; }

a.cta.btn.card-link {
  position: absolute;
  bottom: 0;
  right: 0; }
  a.cta.btn.card-link:before {
    border-bottom: 62px solid #D9000D; }
    @media (min-width: 576px) {
      a.cta.btn.card-link:before {
        border-bottom: 73px solid #D9000D; } }

.card {
  flex-grow: 2; }
  @media (min-width: 1097px) {
    .card {
      flex-grow: 1;
      height: 100%; }
      .card .mb-lg-special {
        margin-bottom: 4.8rem; } }

a.cta {
  transition: all 0.2s ease-out; }
  a.cta:focus, a.cta:focus-within {
    outline: none; }
  a.cta.btn {
    background: #D9000D;
    position: relative;
    margin-left: 0.7rem; }
    a.cta.btn:before {
      transition: all 0.2s ease-out;
      content: "";
      border-left: 0.7rem solid transparent;
      border-bottom: 58px solid #D9000D;
      position: absolute;
      right: 99.999%;
      top: 0; }
      @media (min-width: 576px) {
        a.cta.btn:before {
          border-bottom: 66px solid #D9000D; } }

.btn {
  color: #fff;
  padding: 1rem 2.5rem 1rem 1rem;
  border: none;
  line-height: 1.24em; }
  @media (min-width: 1097px) {
    .btn {
      padding: 1rem; } }
  .btn .small {
    font-family: franklin-gothic-urw, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: italic;
    text-transform: initial; }
  .btn:hover, .btn:focus {
    color: #fff; }

a.cta.btn.right:after {
  transition: all 0.2s ease-out;
  content: "";
  position: absolute;
  border-right: 0.7rem solid transparent;
  border-top: calc(1em * 1.42 + 2rem - 3.5px) solid #D9000D;
  left: 99.999%;
  top: 0; }
  @media (min-width: 576px) {
    a.cta.btn.right:after {
      border-top: calc(1em * 1.42 + 2rem - 3.5px) solid #D9000D; } }

a.cta.btn.right:hover:after {
  border-top-color: #ba000b; }

a.cta.btn.right:hover:before {
  border-bottom-color: #ba000b; }

a.cta.btn.right:hover {
  background: #ba000b; }

.nav-link {
  font-family: prohibition, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.5rem;
  font-style: italic;
  color: #fff;
  margin: 0 auto;
  width: 210px; }
  @media (min-width: 576px) {
    .nav-link {
      font-size: 2rem;
      width: 279px; } }
  @media (min-width: 1097px) {
    .nav-link {
      width: 420px;
      font-size: 3rem; } }
  .nav-link:hover, .nav-link:focus {
    color: #fff; }

.navbar-collapse {
  background: rgba(0, 159, 227, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: height ease-in .1s;
  z-index: 3; }
  @media (max-width: 768px) and (orientation: landscape) {
    .navbar-collapse {
      overflow-y: scroll; }
      .navbar-collapse a.nav-link:last-child {
        margin-bottom: 2rem; } }

div.menu-close {
  justify-content: flex-end; }
  div.menu-close .esc {
    color: #fff;
    font-family: prohibition, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: italic;
    font-size: 1rem;
    margin-right: 18px; }
    @media (min-width: 576px) {
      div.menu-close .esc {
        font-size: 20px;
        padding-top: 3px;
        margin-right: 15px;
        margin-top: 5px; } }
    @media (min-width: 768px) {
      div.menu-close .esc {
        margin-right: 21px; } }
    @media (min-width: 1097px) {
      div.menu-close .esc {
        padding: 0.5rem 0.75rem 0.25rem 0.75rem;
        margin-right: 8px; } }
    div.menu-close .esc:hover, div.menu-close .esc:focus {
      text-decoration: none; }
    div.menu-close .esc img {
      width: 18px;
      height: 18px;
      margin-left: 5px; }
      @media (min-width: 576px) {
        div.menu-close .esc img {
          margin-left: 1.5rem; } }
  @media screen and (min-width: 1000px) {
    div.menu-close {
      padding-right: calc((100vw - 1012px) / 2); } }
  @media screen and (min-width: 1250px) {
    div.menu-close {
      margin: 0 auto;
      width: 100%; } }

@media (max-width: 1096.98px) {
  .navbar-brand svg,
  div.menu-close svg {
    width: 250px; } }

@media (max-width: 767.98px) {
  .navbar-brand svg,
  div.menu-close svg {
    width: 150px; } }

.menu-open {
  font-family: prohibition, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  font-style: italic;
  color: #fff;
  vertical-align: middle; }

nav.navbar {
  position: absolute;
  z-index: 2;
  padding: 0;
  width: 100vw;
  justify-content: space-between;
  align-items: center; }
  @media screen and (min-width: 1250px) {
    nav.navbar {
      align-items: center;
      width: 1250px; } }
  @media screen and (min-width: 1000px) {
    nav.navbar {
      padding-right: calc((100vw - 1012px) / 2); } }

.navbar-toggler {
  border: none; }
  .navbar-toggler .navbar-toggler-icon {
    background-image: url("../images/nav/menu.svg");
    background-size: cover;
    height: 20px; }
  @media (max-width: 767.98px) {
    .navbar-toggler {
      padding-right: .5rem;
      padding-top: 0; } }

.navbar-brand {
  margin-right: 0;
  font-size: 0; }

.carousel-caption {
  position: static;
  display: block;
  margin: 0 auto;
  max-width: 380px;
  text-align: left;
  padding-top: 1.75rem; }

.carousel-indicators {
  bottom: -1.5rem; }
  .carousel-indicators svg {
    margin: .25em; }
    .carousel-indicators svg circle {
      fill: transparent;
      stroke: #fff;
      stroke-width: 1;
      stroke-linecap: butt;
      stroke-dasharray: 0; }
    .carousel-indicators svg.active circle {
      fill: white; }
    .carousel-indicators svg:hover {
      cursor: pointer; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNi4xMjEiIGhlaWdodD0iNDkuNDE0IiB2aWV3Qm94PSIwIDAgMjYuMTIxIDQ5LjQxNCI+CiAgPHBhdGggaWQ9IlBmYWRfNTEiIGRhdGEtbmFtZT0iUGZhZCA1MSIgZD0iTTkzOSwzODMybC0yNCwyNCwyNCwyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkxMy41ODYgLTM4MzEuMjkzKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg=="); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNi4xMjEiIGhlaWdodD0iNDkuNDE0IiB2aWV3Qm94PSIwIDAgMjYuMTIxIDQ5LjQxNCI+CiAgPHBhdGggaWQ9IlBmYWRfNTIiIGRhdGEtbmFtZT0iUGZhZCA1MiIgZD0iTTkzOSwzODMybC0yNCwyNCwyNCwyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTM5LjcwNyAzODgwLjcwNykgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo="); }

.carousel-control-prev {
  left: 0.75rem; }

.carousel-control-next {
  right: 0.75rem; }

.carousel-control-prev,
.carousel-control-next {
  top: 100px;
  align-items: flex-start; }
  @media (min-width: 768px) {
    .carousel-control-prev,
    .carousel-control-next {
      top: 0;
      align-items: center; } }

.carousel-control-next-icon, .carousel-control-prev-icon {
  height: 1.5rem; }
  @media (min-width: 1097px) {
    .carousel-control-next-icon, .carousel-control-prev-icon {
      height: 48px; } }

@keyframes jump {
  0% {
    transform: translateY(0px); }
  5% {
    transform: translateY(10px); }
  10% {
    transform: translateY(0px); }
  15% {
    transform: translateY(5px); }
  20% {
    transform: translateY(0); }
  100% {
    transform: translateY(0); } }

#scroll-down {
  animation: jump, ease-in-out;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-delay: 3s; }

.lightboxOverlay {
  background-color: #009FE3;
  opacity: 0.9; }

.lightbox {
  display: flex;
  align-items: center;
  height: 90%; }
  @media (max-width: 767.98px) {
    .lightbox {
      height: 67%; } }
  @media (min-width: 1097px) {
    .lightbox {
      margin-top: 50px; } }
  .lightbox .lb-image {
    border-width: 10px;
    border-radius: 0; }
    @media (max-width: 767.98px) {
      .lightbox .lb-image {
        border-bottom-width: 0; } }
  .lightbox .lb-outerContainer {
    border-radius: 0; }

.lb-closeContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #86888C;
  z-index: 11; }
  .lb-closeContainer:hover {
    cursor: pointer; }
  .lb-closeContainer .lb-close {
    display: block;
    float: right;
    background: url(../images/icons/close.svg) center center no-repeat;
    padding: 24px;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    transition: opacity 0.2s;
    background-size: 18px; }

.lb-dataContainer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 767.98px) {
    .lb-dataContainer {
      top: 100%;
      padding: 0; } }

.lb-nav {
  transform: translateY(-50%);
  top: 50%;
  height: 50%; }
  .lb-nav a.lb-prev {
    opacity: 1;
    background: url("../images/icons/slider-links.svg") 45% 50% no-repeat;
    background-size: 24px 48px; }
    @media (min-width: 1199px) and (max-width: 1919px) {
      .lb-nav a.lb-prev {
        background-position: 60% 50%; } }
    @media (max-width: 1919px) {
      .lb-nav a.lb-prev {
        filter: drop-shadow(0px 0px 3px #00000029); } }
  .lb-nav a.lb-next {
    opacity: 1;
    background: url("../images/icons/slider-rechts.svg") 77% 50% no-repeat;
    background-size: 24px 48px; }
    @media (min-width: 1199px) and (max-width: 1919px) {
      .lb-nav a.lb-next {
        background-position: 68% 50%; } }
    @media (min-width: 1919px) {
      .lb-nav a.lb-next {
        filter: drop-shadow(0px 0px 3px #00000029); } }

.lb-data {
  padding: 0 10px; }
  @media (max-width: 767.98px) {
    .lb-data {
      margin: 0 -10px; } }
  .lb-data .lb-details {
    background: rgba(0, 159, 227, 0.8);
    padding: 0.5em;
    width: 100%; }
    @media (min-width: 576px) {
      .lb-data .lb-details {
        padding: 1em; } }
    @media (max-width: 767.98px) {
      .lb-data .lb-details {
        background: #009FE3;
        border: solid 10px white;
        border-top: none; } }
    .lb-data .lb-details .lb-caption {
      color: #fff;
      font-style: italic;
      font-family: franklin-gothic-urw, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.8em; }
      @media (min-width: 576px) {
        .lb-data .lb-details .lb-caption {
          line-height: 1em;
          font-size: 1rem; } }

section.leistungsspektrum ul {
  list-style-type: none; }
  section.leistungsspektrum ul li {
    position: relative;
    padding-right: 1rem; }
    section.leistungsspektrum ul li:before {
      content: '—';
      color: #009FE3;
      margin: 0 0 0 -22px;
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 12px;
      vertical-align: 1px; }

section.leistungsspektrum .collapse {
  transition: margin ease-out .1s; }

section.leistungsspektrum .show:not(:last-child) {
  margin-bottom: 1rem; }

section.leistungsspektrum a:hover {
  color: #fff;
  text-decoration: none; }

section.laufbahn ul {
  list-style-type: none;
  border-left: solid 3px #009FE3; }
  section.laufbahn ul li {
    position: relative; }
    section.laufbahn ul li:before {
      content: "";
      position: absolute;
      right: calc(100% + 1.4rem);
      top: 12%;
      background-image: url("../images/icons/kreis-timeline.svg");
      height: 25px;
      width: 25px; }

.slider .carousel-item {
  text-align: center; }
  .slider .carousel-item h2 {
    color: #fff;
    position: relative; }
    .slider .carousel-item h2:before {
      content: "„";
      position: absolute;
      top: -1.25em;
      left: 0;
      font-size: 4rem;
      opacity: 0.5; }
  .slider .carousel-item div.testimonial-img {
    display: inline-block;
    height: 215px;
    width: 215px;
    border: solid 6px #fff;
    transform: skew(-10deg, 0deg);
    overflow: hidden;
    position: relative; }
    .slider .carousel-item div.testimonial-img:before {
      content: "";
      left: -1rem;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      transform: skew(10deg, 0deg); }
    .slider .carousel-item div.testimonial-img.philipp:before {
      width: 280px;
      height: 260px;
      background-image: url("../images/testimonials/philipp-schmidt.jpg"); }

@media (max-width: 1096.98px) {
  .home .hero .h-100 {
    flex-direction: column; } }

section.hero .h-100 {
  position: relative; }

section.hero .h_max {
  color: #fff; }
  @media (min-width: 768px) {
    section.hero .h_max span {
      padding-left: .9rem; } }

section.hero .scroll-down {
  position: absolute;
  text-align: center;
  top: 85%; }
  @media (max-width: 1096.98px) {
    section.hero .scroll-down {
      top: -6rem;
      left: 50%;
      transform: translateX(-50%); } }
  section.hero .scroll-down p {
    font-size: 20px;
    font-style: italic;
    font-family: Prohibition, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase;
    color: #fff; }

section.coach .angle-right {
  position: relative;
  justify-content: center; }
  section.coach .angle-right a {
    z-index: 2;
    white-space: nowrap; }
    @media (min-width: 1097px) {
      section.coach .angle-right a {
        position: absolute;
        bottom: 2rem; } }
    @media (min-width: 1097px) {
      section.coach .angle-right a {
        left: 98%; } }

section.studio {
  display: flex;
  flex-flow: row wrap; }
  section.studio .angle-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around; }
    @media (max-width: 1199.98px) {
      section.studio .angle-right {
        padding: 0 0.75rem; } }
  @media (min-width: 1097px) {
    section.studio {
      flex-wrap: nowrap; }
      section.studio img {
        padding-left: 12px; }
      section.studio .w-lg-50 {
        width: 50%; } }
  section.studio > div {
    width: 100%; }

section.vorteile ul {
  list-style-type: none; }
  @media (min-width: 1097px) {
    section.vorteile ul {
      columns: 2; } }
  section.vorteile ul li {
    break-inside: avoid-column;
    padding: 0.5rem 1.5rem;
    border-bottom: solid 1px #fff;
    color: #fff;
    position: relative; }
    section.vorteile ul li:before {
      content: " ";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 1rem;
      width: 1rem;
      background: no-repeat url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi41MjciIGhlaWdodD0iMTIuNTE2IiB2aWV3Qm94PSIwIDAgMTYuNTI3IDEyLjUxNiI+CiAgPHBhdGggaWQ9IlBmYWRfNzciIGRhdGEtbmFtZT0iUGZhZCA3NyIgZD0iTTY3OCwzNDA3bDQuNzE4LDQuNzE4LDEwLjM5NS0xMC4zOTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02NzcuMjkzIC0zNDAwLjYxNikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo="); }
    @media (min-width: 1097px) {
      section.vorteile ul li {
        margin-right: 2rem; } }

@media (max-width: 767.98px) {
  section.eindrücke > .container {
    text-align: center; } }

section.eindrücke a.lightbox-link {
  display: inline-block;
  position: relative;
  box-shadow: 0px 0px 30px #00000029; }
  section.eindrücke a.lightbox-link:hover {
    cursor: pointer; }
  section.eindrücke a.lightbox-link > span {
    visibility: hidden;
    height: 0;
    display: block;
    background-color: transparent;
    font-style: italic;
    transition: background-color .2s ease-in-out; }
  section.eindrücke a.lightbox-link:hover > span {
    display: flex;
    visibility: visible;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 159, 227, 0.8);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    font-family: prohibition, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
    section.eindrücke a.lightbox-link:hover > span img {
      width: 1rem;
      margin-bottom: 0.5rem; }

section.eindrücke .modal-dialog {
  margin-top: 5%; }

section.eindrücke .modal-body figcaption {
  position: absolute;
  bottom: 0;
  left: 50%;
  background: rgba(0, 159, 227, 0.8);
  transform: translateX(-50%);
  width: 100%;
  padding: .5rem 1rem;
  text-align: center;
  color: #fff; }

section.eindrücke .modal-body img {
  width: 100%; }

section.eindrücke .thumb {
  padding: 1rem 1rem; }

section.eindrücke .close {
  background-color: #86888C;
  opacity: 1;
  position: absolute;
  right: 1px;
  padding: 1rem;
  z-index: 1500; }
  section.eindrücke .close:not(:disabled):not(.disabled):hover, section.eindrücke .close:not(:disabled):not(.disabled):focus {
    opacity: 1; }

section.kontakt .h-100 {
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media screen and (min-width: 1000px) {
    section.kontakt .h-100 {
      padding-left: calc((100vw - 1000px) / 2); } }
  @media (min-width: 1097px) {
    section.kontakt .h-100 {
      justify-content: flex-start;
      align-content: center; } }

section.kontakt .bg-primary > .h1 {
  font-size: 44px; }

@media (min-width: 1097px) {
  section.kontakt div[class^="col-"] {
    height: 620px; } }

section.kontakt h4 {
  font-size: 1rem; }

@media (max-width: 1096.98px) {
  section.kontakt .angle-left {
    display: flex;
    justify-content: center; } }

section.kontakt .angle-left:before {
  border-bottom-color: white;
  mix-blend-mode: normal; }

section.kontakt a.cta {
  z-index: 2;
  white-space: nowrap;
  position: relative; }
  @media (min-width: 1097px) {
    section.kontakt a.cta {
      position: absolute;
      right: -190px;
      bottom: 2rem; } }

section.kontakt address a {
  color: #3B3C43; }
  section.kontakt address a:hover {
    color: #3B3C43; }

section.kontakt #facebook {
  font-size: 0; }

.arbeitsweise .angle-left {
  flex-direction: column;
  justify-content: center; }
  .arbeitsweise .angle-left:before {
    border-bottom-color: #fff;
    mix-blend-mode: normal; }

@media (min-width: 1097px) {
  .arbeitsweise img {
    width: 100%; } }

@media (min-width: 1097px) {
  section.investition div[class*="col"]:nth-child(2) .card {
    margin-right: 1.33rem; }
  section.investition div[class*="col"]:nth-child(3) .card {
    margin: 0 0.66rem; }
  section.investition div[class*="col"]:nth-child(4) .card {
    margin-left: 1.33rem; }
  section.investition a:hover, section.investition a:focus {
    cursor: initial; } }

footer p a {
  color: #3B3C43; }
  footer p a:hover {
    color: #3B3C43; }

footer a:hover {
  color: #009FE3; }

@media (min-width: 1097px) {
  footer .row > div {
    text-align: center; }
    footer .row > div:first-child {
      text-align: left; }
    footer .row > div:last-child {
      text-align: right; } }

section.agb {
  width: 100%; }
  section.agb ol {
    list-style-type: upper-roman; }
    section.agb ol li {
      margin-bottom: 1rem; }
  section.agb a, section.agb a:hover {
    color: #3B3C43; }

body.agb .h-100 {
  display: flex;
  align-items: center; }

body.agb .h_max {
  line-height: 1.05;
  hyphens: manual;
  font-size: 3rem; }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    body.agb .h_max {
      font-size: 4rem; } }
  @media (min-width: 1200px) {
    body.agb .h_max {
      font-size: 5.25rem; } }

section.impressum {
  width: 100%; }
  section.impressum ol {
    list-style-type: upper-roman; }
    section.impressum ol li {
      margin-bottom: 1rem; }
  section.impressum a, section.impressum a:hover {
    color: #3B3C43; }

body.impressum .h_max span {
  padding-left: 0; }

@media (max-width: 1199.98px) {
  body.impressum .h_max {
    font-size: 4rem; } }

@media (max-width: 1199.98px) {
  body.impressum .h-100 {
    justify-content: center; } }

body.datenschutz p {
  font-size: 0.9rem; }

@media (max-width: 1199.98px) {
  body.datenschutz .h_max {
    font-size: 4rem; } }

@media (max-width: 575.98px) {
  body.datenschutz .h_max {
    text-align: center; } }

@media (max-width: 1199.98px) {
  body.datenschutz .h-100 {
    justify-content: center; } }

body.s404 {
  display: flex;
  flex-flow: column;
  justify-content: space-between; }
  @media (max-width: 1199.98px) {
    body.s404 .h_max {
      font-size: 4rem; } }
  body.s404 .h-100.wrapped {
    display: flex;
    align-items: start;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column; }
    @media (min-width: 1097px) {
      body.s404 .h-100.wrapped {
        justify-content: center;
        align-items: flex-start; } }
    body.s404 .h-100.wrapped a.cta {
      align-self: center; }
      @media (min-width: 1097px) {
        body.s404 .h-100.wrapped a.cta {
          align-self: flex-start; } }

@media (min-width: 1097px) {
  .container-fluid, .container-sm, .container-md, .container-lg {
    padding: 0; } }

ul {
  padding-inline-start: 0; }

* ::selection {
  background: #009FE3;
  color: #fff; }

a:hover {
  text-decoration: none; }

body {
  width: 100%;
  height: 100vh; }

@media (min-width: 1097px) {
  .wrapped {
    max-width: 625px; } }
