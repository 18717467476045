section.leistungsspektrum {
  ul {
    list-style-type: none;

    li {
      position: relative;
      padding-right: 1rem;

      &:before {
        content: '—';
        color: $hitcoachBlue;
        margin: 0 0 0 -22px;

        display: inline-block;
        position: absolute;

        width: 17px;
        height: 12px;
        vertical-align: 1px
      }
    }
  }
  .collapse {
    transition: margin ease-out .1s;
  }

  .show:not(:last-child) {
    margin-bottom: 1rem;
  }

  a:hover {
    color: $hitcoachNegative;
    text-decoration: none;
  }
}
