.nav-link {
  font-family: prohibition, $font-family-sans-serif;
  font-size: 1.5rem;
  font-style: italic;
  color: $white;
  margin: 0 auto;
  width: 210px;

  @include media-breakpoint-up(sm) {
  }

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
    width: 279px;
  }

  @include media-breakpoint-up(lg) {
    width: 420px;
    font-size: 3rem;
  }

  &:hover, &:focus {
    color: $hitcoachNegative;
  }
}


.navbar-collapse {
  background: transparentize($primary, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: height ease-in .1s;
  z-index: 3;

  @media (max-width: 768px) and (orientation: landscape) {
    overflow-y: scroll;
    a.nav-link:last-child {
      margin-bottom: 2rem;
    }
  }
}

div.menu-close {
  justify-content: flex-end;

  .esc {
    color: $white;
    font-family: prohibition, $font-family-sans-serif;
    font-style: italic;
    font-size: 1rem;
    margin-right: 18px;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
      padding-top: 3px;
      margin-right: 15px;
      margin-top: 5px;
    }
    @include media-breakpoint-up(md) {
      margin-right: 21px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0.5rem 0.75rem 0.25rem 0.75rem;
      margin-right: 8px;
    }

    &:hover, &:focus {
      text-decoration: none;
    }

    img {
      width: 18px;
      height: 18px;
      margin-left: 5px;
      @include media-breakpoint-up(sm) {
        margin-left: 1.5rem;
      }
    }
  }

  @media screen and (min-width: 1000px) {
    padding-right: calc((100vw - 1012px) / 2);
  }

  @media screen and(min-width: 1250px) {
    margin: 0 auto;
    width: 100%;
  }
}


.navbar-brand svg,
div.menu-close svg {
  @include media-breakpoint-down(md) {
    width: 250px;
  }
  @include media-breakpoint-down(sm) {
    width: 150px;
  }
}


.menu-open {
  font-family: prohibition, $font-family-sans-serif;
  font-size: 20px;
  font-style: italic;
  color: $white;
  vertical-align: middle;
}

nav.navbar {
  position: absolute;
  z-index: 2;
  padding: 0;
  width: 100vw;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1250px) {
    align-items: center;
    width: 1250px;
  }

  @media screen and (min-width: 1000px) {
    padding-right: calc((100vw - 1012px) / 2);
  }
}

.navbar-toggler {
  border: none;

  .navbar-toggler-icon {
    background-image: url("../images/nav/menu.svg");
    background-size: cover;
    height: 20px;
  }

  @include media-breakpoint-down(sm) {
    padding-right: .5rem;
    padding-top: 0;
  }
}

.navbar-brand {
  margin-right: 0;
  font-size: 0;
}
