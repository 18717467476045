section.impressum {
  width: 100%;

  ol {
    list-style-type: upper-roman;

    li {
      margin-bottom: 1rem;
    }
  }

  a, a:hover {
    color: $body-color;

  }
}

body.impressum {
  .h_max {
    span {
      padding-left: 0;
    }
    @include media-breakpoint-down(lg) {
      font-size: 4rem;
    }
  }
  .h-100{
    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
  }
}
