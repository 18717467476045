a.btn-primary {
  position: relative;
  margin-bottom: 2px;

  &[aria-expanded="true"] {
    margin-bottom: 0;
  }

  &[aria-expanded="false"]:after {
    background-image: url("/images/icons/+.svg");
  }

  &:after {
    content: "";
    height: 1.2rem;
    width: 1.2rem;
    background-image: url('/images/icons/-.svg');

    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-100%, -50%);
  }
}

