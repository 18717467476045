body.datenschutz {
  p {
    font-size: 0.9rem;
  }

  .h_max {
    @include media-breakpoint-down(lg) {
      font-size: 4rem;
    }
    @include media-breakpoint-down(xs) {
      text-align: center
    }
  }

  .h-100 {
    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
  }

}

