.lightboxOverlay {
  background-color: $hitcoachBlue;
  opacity: 0.9;
}

.lightbox {
  display: flex;
  align-items: center;
  height: 90%;

  @include media-breakpoint-down(sm) {
    height: 67%;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 50px;
  }

  .lb-image {
    border-width: 10px;
    border-radius: 0;

    @include media-breakpoint-down(sm) {
      border-bottom-width: 0;
    }
  }

  .lb-outerContainer {
    border-radius: 0;
  }
}


.lb-closeContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  background: $hitcoachLightGray;
  // z-index nav elements is 10
  z-index: 11;

  &:hover {
    cursor: pointer;
  }

  .lb-close {
    display: block;
    float: right;

    background: url(../images/icons/close.svg) center center no-repeat;
    padding: 24px;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    transition: opacity 0.2s;
    background-size: 18px;
  }
}

.lb-dataContainer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  @include media-breakpoint-down(sm) {
    top: 100%;
    padding: 0;
  }
}

.lb-nav {

  transform: translateY(-50%);
  top: 50%;
  height: 50%;

  a {
    &.lb-prev {
      opacity: 1;
      background: url('../images/icons/slider-links.svg') 45% 50% no-repeat;
      background-size: 24px 48px;

      @media (min-width: 1199px) and (max-width: 1919px) {
        background-position: 60% 50%;
      }

      @media (max-width: 1919px) {
        filter: drop-shadow(0px 0px 3px #00000029);
      }
    }

    &.lb-next {
      opacity: 1;
      background: url('../images/icons/slider-rechts.svg') 77% 50% no-repeat;
      background-size: 24px 48px;

      @media (min-width: 1199px) and (max-width: 1919px) {
        background-position: 68% 50%;
      }

      @media (min-width: 1919px) {
        filter: drop-shadow(0px 0px 3px #00000029);
      }
    }
  }
}

.lb-data {
  padding: 0 10px;

  @include media-breakpoint-down(sm) {
    margin: 0 -10px;
  }

  .lb-details {
    background: rgba($hitcoachBlue, 0.8);
    padding: 0.5em;

    @include media-breakpoint-up(sm) {
      padding: 1em;
    }
    width: 100%;

    @include media-breakpoint-down(sm) {
      background: $hitcoachBlue;
      border: solid 10px white;
      border-top: none;
    }

    .lb-caption {
      color: $hitcoachNegative;
      font-style: italic;
      font-family: franklin-gothic-urw, $font-family-sans-serif;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.8em;

      @include media-breakpoint-up(sm) {
        line-height: 1em;
        font-size: 1rem;
      }
    }
  }
}
