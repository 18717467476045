h4.card-subtitle {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 1.5rem;
}

.card-body {
  position: relative;
  padding-bottom: 5rem;
}

a.cta.btn.card-link {
  position: absolute;
  bottom: 0;
  right: 0;

  &:before {
    border-bottom: 62px solid $hitcoachRed;
    @include media-breakpoint-up(sm) {
      border-bottom: 73px solid $hitcoachRed;
    }
  }
}

.card {
  flex-grow: 2;

  @include media-breakpoint-up(lg) {
    flex-grow: 1;
    height: 100%;

    .mb-lg-special {
      margin-bottom: 4.8rem;
    }
  }

}
