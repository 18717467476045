section.vorteile {
  ul {
    list-style-type: none;
    @include media-breakpoint-up(lg) {
      columns: 2;
    }

    li {
      break-inside: avoid-column;
      padding: 0.5rem 1.5rem;
      border-bottom: solid 1px $white;
      color: $hitcoachNegative;
      position: relative;

      &:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 1rem;
        width: 1rem;
        background: no-repeat url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi41MjciIGhlaWdodD0iMTIuNTE2IiB2aWV3Qm94PSIwIDAgMTYuNTI3IDEyLjUxNiI+CiAgPHBhdGggaWQ9IlBmYWRfNzciIGRhdGEtbmFtZT0iUGZhZCA3NyIgZD0iTTY3OCwzNDA3bDQuNzE4LDQuNzE4LDEwLjM5NS0xMC4zOTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02NzcuMjkzIC0zNDAwLjYxNikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=");
      }

      @include media-breakpoint-up(lg) {
        margin-right: 2rem;
      }
    }
  }
}
