//COLORS
$hitcoachBlue: #009FE3;
$hitcoachDarkGray: #3B3C43;
$hitcoachLightGray: #86888C;
$hitcoachNegative: #fff;
$hitcoachRed: #D9000D;

//theme
$primary: $hitcoachBlue;
$secondary: $hitcoachRed;
$active-background: $hitcoachRed;
//grid
.wrap {
  max-width: 1250px;
  margin: 0 auto;
}

$grid-gutter-width: 0;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1000px,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1097px,
        xl: 1200px
) !default;

//Body
$body-color: $hitcoachDarkGray;
$body-bg: $hitcoachNegative;

//font
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: franklin-gothic-urw, $font-family-sans-serif;
$font-weight-base: 400;
$line-height-base: 1.42;
$link-hover-color: inherit;
$text-muted: $hitcoachLightGray;

//spacer
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * 1),
                        2: ($spacer * 2),
                        3: ($spacer * 3),
                        4: ($spacer * 4),
                        75: ($spacer * .75),
                        8: ($spacer * 8)
                ),
                $spacers
);

$border-width: 2px;
$card-border-radius: 0;
$card-border-width: 0;


//nav
$navbar-dark-toggler-border-color: transparent;
$navbar-dark-active-color: $hitcoachNegative;
$navbar-dark-color: $hitcoachNegative;

$modal-backdrop-bg: $hitcoachBlue;

//carousel
$carousel-control-opacity: 1;
// Modal
$modal-inner-padding: .25rem;
$modal-content-border-radius: 0;
$modal-content-border-width: 0;
$modal-footer-border-color: transparent;
$modal-md: 60vw;
$close-font-size: 0;
/*z. B. Abstand zwischen Paragraphen
smallMargin: 1rem; m*-1
*/

/*z. B. Abstand zwischen Headlines und Paragraphen
middleMargin: 2rem; m*-2
*/

/*z. B. Abstand zwischen Section Hero Section der Coach
bigMargin: 4rem; m*-4
*/

/*z. B. Abstand zwischen Section der Coach und Section meine Laufbahn
maxMargin: 8rem; m*-8
*/
