$angle-width: .7rem;
a.cta {
  transition: all 0.2s ease-out;
  &:focus, &:focus-within {
    outline: none;
  }

  &.btn {
    background: $hitcoachRed;
    position: relative;
    margin-left: $angle-width;

    &:before {
      transition: all 0.2s ease-out;
      content: "";
      border-left: $angle-width solid transparent;
      border-bottom: 58px solid $hitcoachRed;
      @include media-breakpoint-up(sm) {
        border-bottom: 66px solid $hitcoachRed;
      }
      position: absolute;
      right: 99.999%;
      top: 0;
    }
  }
}

.btn {
  color: $hitcoachNegative;

  padding: 1rem 2.5rem 1rem 1rem;
  border: none;
  line-height: 1.24em;

  @include media-breakpoint-up(lg) {
    padding: 1rem;
  }

  .small {
    font-family: franklin-gothic-urw, $font-family-sans-serif;
    font-style: italic;
    text-transform: initial;
  }

  &:hover, &:focus {
    color: $hitcoachNegative;
  }
}

a.cta.btn.right {
  &:after {
    transition: all 0.2s ease-out;
    content: "";
    position: absolute;
    border-right: $angle-width solid transparent;
    border-top: calc(1em * #{$line-height-base} + 2rem - 3.5px) solid $hitcoachRed;
    @include media-breakpoint-up(sm) {
      border-top: calc(1em * #{$line-height-base} + 2rem - 3.5px) solid $hitcoachRed;
    }
    left: 99.999%;
    top: 0;
  }
  $darken-amount: 6%;

  &:hover:after {
    border-top-color: darken($hitcoachRed, $darken-amount);
  }

  &:hover:before {
    border-bottom-color: darken($hitcoachRed, $darken-amount);
  }

  &:hover {
    background: darken($hitcoachRed, $darken-amount);
  }
}
