section.studio {
  display: flex;
  flex-flow: row wrap;

  .angle-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    @include media-breakpoint-down(lg) {
      padding: 0 0.75rem;
    }
  }

  @include media-breakpoint-up(lg) {

    img {
      padding-left: 12px;
    }

    .w-lg-50 {
      width: 50%;
    }
    flex-wrap: nowrap;
  }

  > div {
    width: 100%;
  }
}
