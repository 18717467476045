
.slider .carousel-item {
  text-align: center;

  h2 {
    color: $hitcoachNegative;
    position: relative;

    &:before {
      content: "„";
      position: absolute;
      top: -1.25em;
      left: 0;
      font-size: 4rem;
      opacity: 0.5;
    }
  }

  div.testimonial-img {
    display: inline-block;
    height: 215px;
    width: 215px;
    border: solid 6px $hitcoachNegative;
    transform: skew(-10deg, 0deg);
    overflow: hidden;
    position: relative;

    &:before {
      content: "";
      left: -1rem;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      transform: skew(10deg, 0deg);
    }

    &.philipp:before {
      width: 280px;
      height: 260px;
      background-image: url("../images/testimonials/philipp-schmidt.jpg");
    }
  }
}
