.negative {
  color: $hitcoachNegative;
}

html {
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  hyphens: auto;
}

body {
  margin: 0;
  font-style: normal;
  text-align: left;
}

.small {
  font-size: 0.66rem;
}

.bold {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

.strong {
  color: $hitcoachBlue;
  font-weight: 500;
  font-style: italic;
}

.h_max, .h1, .h3, .btn {
  font-family: prohibition, $font-family-sans-serif;
  font-weight: 400;
  font-style: italic;
  color: $hitcoachBlue;
  letter-spacing: .02em;
  text-transform: uppercase;
}

.btn {
  font-size: 1rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.38rem;
  }
  font-family: prohibition, $font-family-sans-serif;

  &.btn-primary {
    background: $hitcoachBlue;
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 100%;
}


/*Die Headline im Hero*/
.h_max {
  font-size: 4rem;
  line-height: 90%;

  @include media-breakpoint-up(sm) {
    font-size: 6.55rem;
  }
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.38rem;
  font-weight: 500;
  font-style: italic;
  color: $hitcoachLightGray;
}

h3, .h3 {
  font-size: 1.77rem;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
  color: $hitcoachBlue;
}

h4 {
  font-size: 1.22rem;

}

h5, h6 {
  font-size: 1rem;
}

.h1 + span.h2 {
  margin-top: -1.5rem;
  margin-bottom: 1rem;
  display: block;
}
